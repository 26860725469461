import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersJobPosting = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Head of Operations</h1>
      <div className="jobdescription col-md-12">
        A flawless product and service require an impeccable operation.
      </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Make Uno a well-oiled machine by looking after our employees,
              contracts, compliance and finance reporting.
            </li>
            <li>
              Provide hands-on management of day-to-day operations and lead new
              work streams.
            </li>
            <li>
              Facilitate relationships with vendors, contractors and partners to
              deliver Uno products and services on time and budget.
            </li>
            <li>Establish and grow our technical servicing team in the US.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              Always looking ahead and can foresee problems before they arise.
            </li>
            <li>Organized and detail-oriented.</li>
            <li>
              Able to assess and resolve complex situations, dependencies and
              relationships.
            </li>
            <li>Responsible beyond your predefined tasks.</li>
            <li>
              Thorough in your thinking process and comfortable making hard
              decisions.
            </li>
            <li>
              A natural leader who helps others get the most out of themselves.
            </li>
            <li>
              Willing to travel in the US and internationally when necessary.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              8+ years of running operations at a company delivering a physical
              consumer product.
            </li>
            <li>Extensive experience in project and team management. </li>
            <li>
              Deep understanding of common business practices, policies,
              negotiations, contracts and manufacturing.
            </li>
            <li>
              Proven ability in overseeing HR related tasks such as payroll,
              benefits, worker’s comp, etc.{" "}
            </li>
            <li>
              Supply chain, warehouse and inventory management experience.{" "}
            </li>
            <li>
              High proficiency in financial planning, monitoring, bookkeeping,
              reporting, etc.{" "}
            </li>
            <li>Awareness to regulation and compliance.</li>
            <li>Exposure to Salesforce and its capabilities.</li>
            <li>Experience running on-the-ground hardware servicing teams. </li>
            <li>Experience running Saas operation is a plus.</li>
            <li>Startup experience is a plus.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersJobPosting;
